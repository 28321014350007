import type { ConsentCategory, ConsentState, SCCConfig } from '@m10s/cmp'

import { getEnv, isProductionBuild } from '../../env'
import { getCurrentLanguage } from '../../utils/i18n'
import { reportError } from '../sentry'

enum ConsentLanguages {
  en = 'en',
  no = 'no',
  se = 'se',
  fi = 'fi',
  dk = 'dk',
}

export type UnsubscribeFunction = () => void

declare const window:
  | ({
      _scc_?: {
        getPermission: (category: ConsentCategory, callback: (value: ConsentState) => void) => void
        getPermissionSync: (category: ConsentCategory) => ConsentState
        subscribe: (category: ConsentCategory, callback: (value: ConsentState) => void) => UnsubscribeFunction
        showPrivacyManager: () => void
      }
    } & Window)
  | undefined

// TODO: fix language code type
type SiteLanguage = 'en' | 'fr' | 'sv' | 'fi' | 'no'
/* Doing this mapping since some of our default languages have different codes or are not supported */
const consentLanguageMap: Record<SiteLanguage, ConsentLanguages> = {
  sv: ConsentLanguages.se,
  en: ConsentLanguages.en,
  fi: ConsentLanguages.fi,
  fr: ConsentLanguages.en,
  no: ConsentLanguages.no,
}

export const getIsSourcepointClientActive = () => {
  return typeof window !== 'undefined' && Boolean(window._scc_)
}
export function showSchibstedCookieConsentDialog() {
  if (!getIsSourcepointClientActive()) {
    return
  }
  window?._scc_?.showPrivacyManager()
}
export const getSchibstedCookieConsentValue = (category: ConsentCategory) => {
  if (!getIsSourcepointClientActive()) {
    return false
  }
  const value = window?._scc_?.getPermissionSync(category)

  return value ? convertSourcepointValueToBoolean({ value }) : false
}

type SubscribeToSourcepointCategoryConsentProps = {
  callbackFunction: (value: ConsentState) => void
  category: ConsentCategory
}
export function subscribeToSourcepointCategoryConsent({
  callbackFunction,
  category,
}: SubscribeToSourcepointCategoryConsentProps) {
  return window?._scc_?.subscribe(category, callbackFunction)
}

export function convertSourcepointValueToBoolean({ value }: { value: ConsentState }) {
  return value === '1' ? true : false
}

export async function initSchibstedCookieConsent() {
  if (getIsSourcepointClientActive()) {
    return
  }
  const propertyHref = getEnv('SCHIBSTED_COOKIE_CONSENT_PROPERTY_HREF')
  const currentLanguage = getCurrentLanguage()
  const config: SCCConfig = {
    baseEndpoint: getEnv('SCHIBSTED_COOKIE_CONSENT_BASE_ENDPOINT')!,
    propertyId: parseInt(getEnv('SCHIBSTED_COOKIE_CONSENT_PROPERTY_ID') || '', 10),
    consentLanguage: consentLanguageMap[currentLanguage as SiteLanguage],
    groupPmId: parseInt(getEnv('SCHIBSTED_COOKIE_CONSENT_GROUP_PM_ID') || '', 10),
    ...(propertyHref && { propertyHref }),
  }
  // We need to use dynamic import here to avoid loading the sourcepoint client in the server
  if (window) {
    try {
      const { scc } = await import('@m10s/cmp')
      scc(window, document, navigator, config)
    } catch (error) {
      if (isProductionBuild()) {
        reportError('Could not initialize @m10s/cmp cookie consent manager', { error })
      }
    }
  }
}
